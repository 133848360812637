import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { MCNFormContext } from '../../../contexts/MCNFormContext';

const useStyles = makeStyles(theme => ({
  product: {
    padding: "15px",
    marginTop: "25px",
  },
  longText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ...theme.common,
}));

const Recap = ({ previousForm, formSubmitted }) => {
  const classes = useStyles();
  const { products, getTotals } = React.useContext(MCNFormContext);

  const totals = getTotals();
  
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          {
            products.map(product => (
              <Paper key={product.code} className={classes.product} variant="outlined">
                <Grid container direction="row" justify="space-around"  alignItems="center" spacing={2}>
                  <Grid className={classes.longText} item xs={6}>
                    {product.name} (lot N°{product.lotNumber})
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="filled-qt"
                      label="Quantité (Kg)"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={product.qt}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ inputMode: 'numeric' }}
                      onKeyDown={(evt) => ["e", "E", "+", "-", ".", ","].includes(evt.key) && evt.preventDefault()}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="filled-price"
                      label="Prix"
                      type="number"
                      fullWidth
                      variant="outlined"
                      inputProps={{ step: "0.01", inputMode: 'numeric' }}
                      InputLabelProps={{ shrink: true }}
                      value={product.price}
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ))
          }
          {
            <Paper className={classes.product} variant="outlined">
              <Grid container direction="row" justify="space-around"  alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h6">Total HTVA</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" align="right">{totals.priceHTVA.toFixed(2)} €</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">TVA</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" align="right">{totals.vat.toFixed(2)} €</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">Total TVAC</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <u><Typography variant="h6" align="right">{totals.priceTVAC.toFixed(2)} €</Typography></u>
                  </Grid>
              </Grid>
            </Paper>
          }
        </Grid>
        <Grid item xs={6}>
          <Button className={classes.btnConfirm} fullWidth color="secondary" variant="contained" onClick={previousForm}>
            Retour
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button className={classes.btnConfirm} fullWidth color="primary" variant="contained" onClick={formSubmitted}>
            Confirmer
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Recap;