import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { DialogContext } from "../contexts/DialogContext";
import { AirtableContext } from "../contexts/AirtableContext";
import { MultiStepForm } from "./components";
import ProvideMCNFormContext from "../contexts/MCNFormContext";
import { CustomizedSnackBarContext } from "../contexts/CustomizedSnackbarContext";
import {
  Button,
  CircularProgress,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import LotVenteInfo from "./components/LotVenteInfo/LotVenteInfo";

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    // margin: '40px 16px',
  },
  fullHeight: {
    height: "90%",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  rowHead: {
    fontWeight: "600",
  },
});
const useStyles = makeStyles((_) => ({
  btn: {
    marginTop: "16px",
    marginBottom: "16px",
  },
}));

const PopupAchatInfo = ({ id }) => {
  const classes = useStyles();

  const { loadingData, venteDetails, getVenteDetails, sendBdC } =
    React.useContext(AirtableContext);
  const { successMessage, errorMessage } = React.useContext(
    CustomizedSnackBarContext
  );

  React.useEffect(() => {
    getVenteDetails(id);
  }, []);

  const sendBonDeCommande = async (id) => {
    const response = await sendBdC(id);
    if (response) {
      successMessage("Votre requête est envoyée avec succès !");
    } else {
      errorMessage("Une erreur s'est produite lors de l'envoi");
    }
  };

  return (
    <React.Fragment>
      {loadingData && <CircularProgress />}
      {loadingData === false && (
        <React.Fragment>
          {venteDetails.map((venteDetail) => (
            <LotVenteInfo
              key={venteDetail.id}
              venteId={id}
              venteDetail={venteDetail}
            />
          ))}
        </React.Fragment>
      )}
      <Button
        className={classes.btn}
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => sendBonDeCommande(id)}
      >
        Envoyer bon de commande
      </Button>
    </React.Fragment>
  );
};

function Content(props) {
  const { classes } = props;

  const { open, closeDialogPlease, setOnExitCallback } =
    React.useContext(DialogContext);
  const { clients, ventes, loadingData, livreurs, fetchLivreursVentes } =
    React.useContext(AirtableContext);

  React.useEffect(() => {
    setOnExitCallback(fetchLivreursVentes);
    fetchLivreursVentes();
  }, []);

  const addBtnHandler = () => {
    open("Nouvelle livraison", "", () => (
      <ProvideMCNFormContext>
        <MultiStepForm closeDialogPlease={closeDialogPlease} />
      </ProvideMCNFormContext>
    ));
  };

  const openEditPage = (id) => {
    open(
      "Information Bon de commande",
      "Ci-dessous les informations du bon de commande",
      () => <PopupAchatInfo id={id} />
    );
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Liste"
                  disabled
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          {loadingData === true ? (
            <CircularProgress />
          ) : !ventes || ventes.length === 0 ? (
            <Typography color="textSecondary" align="center">
              Aucune info à afficher
            </Typography>
          ) : (
            <TableContainer>
              <Table className={classes.table} aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell>Modifier</TableCell>
                    {/* <TableCell className={classes.rowHead}>Date</TableCell> */}
                    <TableCell className={classes.rowHead}>Client</TableCell>
                    <TableCell className={classes.rowHead} align="right">
                      Prix
                    </TableCell>
                    <TableCell align="left" width={25}>
                      BdC
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ventes.map((vente) => (
                    <TableRow key={vente.id}>
                      <TableCell>
                        <IconButton
                          color="primary"
                          aria-label="add"
                          onClick={() => openEditPage(vente.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      {/* <TableCell>{new Date(vente.date).toLocaleDateString()}</TableCell> */}
                      <TableCell>{vente.name}</TableCell>
                      <TableCell align="right">
                        {vente.price.toFixed(2)} €
                      </TableCell>
                      <TableCell>
                        {vente.url && vente.url[0] ? (
                          <IconButton
                            color="primary"
                            href={vente.url[0].url}
                            target="_blank"
                          >
                            <PictureAsPdfIcon />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Paper>
      {livreurs && livreurs.length > 0 && (
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={addBtnHandler}
        >
          <AddIcon />
        </Fab>
      )}
    </React.Fragment>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);
