import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FirstForm, SecondForm, FinalForm, Recap } from './MultiStepForms';
import { CustomizedSnackBarContext } from '../../contexts/CustomizedSnackbarContext';
import { MCNFormContext } from '../../contexts/MCNFormContext';

const useStyles = makeStyles(theme => ({
  root: {
  },
  btnConfirm: {
    marginTop: "35px",
  },
  ...theme.common,
}));

const MultiStepForm = ({ closeDialogPlease }) => {
  const classes = useStyles();
  
  const [step, setStep] = React.useState(1);
  const { errorMessage } = React.useContext(CustomizedSnackBarContext);
  const { products } = React.useContext(MCNFormContext);

  const formSubmitted = () => {
    // On second form we have to check the value entered
    if (step === 2) {
      // Ensure there are at least a products
      if (!products || products.length === 0) {
        errorMessage("Aucun produit selectionné");
        return;
      }

      for (let i = 0; i < products.length; i++) {
        const element = products[i];

        const qt = Number(element.qt);
        if (!qt) {
          errorMessage("Une quantité entrée n'est pas correct");
          return
        }
        
        if (qt > element.qtLeft) {
          errorMessage("Le lot " + element.name + "(" + element.lotNumber + ") n'a plus que " + element.qtLeft + "kg disponible");
          return
        }

        const price = Number(element.price);
        if (!price) {
          errorMessage("Un prix entré n'est pas correct");
          return
        }
      }

      setStep(step + 1);
    } else {
      setStep(step + 1);
    }
  };

  return (
    <React.Fragment>
      {
        step === 1 && (
          <FirstForm formSubmitted={formSubmitted} />
        )
      }
      {
        step === 2 && (
          <SecondForm formSubmitted={formSubmitted} previousForm={() => setStep(step - 1)}/>
        )
      }
      {
        step === 3 && (
          <Recap formSubmitted={formSubmitted} previousForm={() => setStep(step - 1)}/>
        )
      }
      {
        step === 4 && (
          <FinalForm closeDialogPlease={closeDialogPlease} />
        )
      }
    </React.Fragment>
  );
};

export default MultiStepForm;