import React from "react";
import { MCNFormContext } from "../../../contexts/MCNFormContext";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { AirtableContext } from "../../../contexts/AirtableContext";
import { CustomizedSnackBarContext } from "../../../contexts/CustomizedSnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  barcodeForm: {
    marginBottom: "25px",
  },
  btnConfirm: {
    marginTop: "25px",
  },
  product: {
    padding: "15px",
    marginTop: "25px",
  },
  longText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ...theme.common,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SecondForm = ({ formSubmitted }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState("");
  const inputRef = React.useRef();
  const { searchBatch, retrieveBatch } = React.useContext(AirtableContext);
  const { errorMessage } = React.useContext(CustomizedSnackBarContext);
  const { products, addProduct, removeProduct, updateProp } =
    React.useContext(MCNFormContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading("SEARCHING");

    var lot = await retrieveBatch(e.target[0].value);
    e.target[0].value = "";

    if (lot) {
      addProduct(
        lot.id,
        lot.get("ProductName"),
        lot.get("LotProd"),
        lot.get("Quantité restante (kg)")
      );
    } else {
      errorMessage("Le code barre n'a aucune correspondance en DB");
    }

    setLoading("");
  };

  const onSubmitSearch = async (e) => {
    e.preventDefault();
    setLoading("SEARCHING");
    var lot = await searchBatch(e.target[0].value);
    e.target[0].value = "";

    if (lot) {
      addProduct(
        lot.id,
        lot.get("ProductName"),
        lot.get("LotProd"),
        lot.get("Quantité restante (kg)")
      );
    } else {
      errorMessage("Le numéro de lot encodé n'a aucune correspondance en DB");
    }

    setLoading("");
  };

  const deleteProduct = (product) => {
    removeProduct(product);
    inputRef.current.focus();
  };

  return (
    <React.Fragment>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          aria-label="simple tabs example"
        >
          <Tab label="Scanner" {...a11yProps(0)} />
          <Tab label="Encodage" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <form className={classes.barcodeForm} onSubmit={onSubmit}>
          <TextField
            inputRef={inputRef}
            autoFocus
            id="barcode-scanner"
            label="Code bar"
            type="text"
            fullWidth
            variant="filled"
            required
            InputProps={{
              endAdornment: loading && <CircularProgress />,
            }}
          />
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <form className={classes.barcodeForm} onSubmit={onSubmitSearch}>
          <ButtonGroup fullWidth variant="contained" color="primary">
            <TextField
              inputRef={inputRef}
              autoFocus
              id="lot-nbre-input"
              label="Numéro de lot"
              type="text"
              fullWidth
              variant="filled"
              required
              disabled={loading}
              onKeyDown={(evt) => {
                if (
                  [
                    "-",
                    "Backspace",
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ].includes(evt.key) === false
                ) {
                  evt.preventDefault();
                  if (evt.key === "," || evt.key === ".") {
                    evt.target.value += "-";
                  }
                }
              }}
              inputProps={{
                inputMode: /iPad|iPhone|iPod/.test(navigator.userAgent)
                  ? "decimal"
                  : "numeric",
              }}
            />
            <Button disabled={loading} type="submit">
              {loading && <CircularProgress />}Rechercher
            </Button>
          </ButtonGroup>
        </form>
      </TabPanel>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        {products.map((product) => (
          <Paper
            key={product.code}
            className={classes.product}
            variant="outlined"
          >
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={2}
            >
              <Grid className={classes.longText} item xs={6}>
                {product.name} (lot N°{product.lotNumber})
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  aria-label="add an alarm"
                  onClick={() => deleteProduct(product)}
                >
                  <DeleteForeverIcon />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="filled-qt"
                  label="Quantité (Kg)"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={product.qt}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric", max: product.qtLeft }}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={(event) =>
                    updateProp("qt", product.code, event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="filled-price"
                  label="Prix"
                  type="number"
                  fullWidth
                  variant="outlined"
                  inputProps={{ step: "0.01", inputMode: "decimal" }}
                  InputLabelProps={{ shrink: true }}
                  value={product.price}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={(event) =>
                    updateProp("price", product.code, event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
      <Button
        className={classes.btnConfirm}
        fullWidth
        color="primary"
        variant="contained"
        onClick={formSubmitted}
        disabled={products.length === 0 || loading}
      >
        Confirmer
      </Button>
    </React.Fragment>
  );
};

export default SecondForm;
