import React from 'react';
import './App.css';
import ProvideAirtableContext from './contexts/AirtableContext';
import ProvideCustomizedSnackBarContext from './contexts/CustomizedSnackbarContext';
import ProvideDialogContext from './contexts/DialogContext';
import { Paperbase } from './Paperbase';

function App() {
  return (
    <div className="App">
      <ProvideCustomizedSnackBarContext>
        <ProvideAirtableContext>
          <ProvideDialogContext>
            <Paperbase />
          </ProvideDialogContext>
        </ProvideAirtableContext>
      </ProvideCustomizedSnackBarContext>
    </div>
  );
}

export default App;
