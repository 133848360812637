import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { 
  Dialog,
} from '@material-ui/core';
import { DialogContent, DialogTitle } from './components';

export const DialogContext = React.createContext({});

// Dont forget to add this at the root of your project around your <App />
export default class ProvideDialogContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      title: null,
      subTitle: null,
      content: null,
      className: null,
      onExitCallback: null,
      open: (title, subTitle, content) => this.setState({ title, subTitle, content, isOpen: true }),
      closeDialogPlease: () => this.setState({ isOpen: false }),
      setClassName: className => this.setState({ className }),
      setOnExitCallback: onExitCallback => this.setState({ onExitCallback }),
    };
  }

  componentDidUpdate() { }

  componentDidMount() { }

  closeDialog = () => this.setState({ isOpen: false });

  onExited = () => {
    if (this.state.onExitCallback) {
      this.state.onExitCallback();
    }
  }

  render() {

    return (
      <React.Fragment>
        <DialogContext.Provider value={this.state}>{this.props.children}</DialogContext.Provider>
        
        <Dialog fullScreen maxWidth={'md'} aria-labelledby="customized-dialog-title" open={this.state.isOpen} onBackdropClick={this.closeDialog} onExited={this.onExited}>
          <DialogTitle id="customized-dialog-title" onClose={this.closeDialog}>
            {this.state.title}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              {this.state.subTitle}
            </DialogContentText>
            {this.state.content && this.state.content()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}