import React from 'react';
import { Button, ButtonGroup, CircularProgress, Dialog, DialogContentText, Grid, MenuItem, TextField } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import PaymentIcon from '@material-ui/icons/Payment';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/styles';
import { MCNFormContext } from '../../../contexts/MCNFormContext';
import { AirtableContext } from '../../../contexts/AirtableContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DialogContent, DialogTitle } from '../../../contexts/components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '75px',
  },
  continueBtn: {
    marginTop: "55px",
  },
  addClientBtn: {
    width: "15%",
  },
  ...theme.common,
}));

const FirstForm = ({ formSubmitted }) => {
  const classes = useStyles();

  const { payment, setChange } = React.useContext(MCNFormContext);
  const { clients, loadingData, reloadClients } = React.useContext(AirtableContext);
  
  const [open, setOpen] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    formSubmitted();
  };

  const closeDialog = () => setOpen(false);

  const onExited = () => {
    reloadClients();
  }
  
  return (
    <>
      <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {
              loadingData
              ? (
                <>
                  <CircularProgress /> Chargement des clients...
                </>
              )
              : (
                <ButtonGroup fullWidth disableElevation variant="contained" color="primary">
                    <Autocomplete
                      id="combo-box-demo"
                      disabled={loadingData}
                      options={clients}
                      fullWidth
                      getOptionLabel={(option) => option.pseudo}
                      onChange={(_, newValue) => setChange('client', newValue ? newValue.id : undefined) }
                      renderInput={(params) => <TextField {...params} label="Choisir un client" variant="filled" required />}
                    />
                    <Button className={classes.addClientBtn} variant="contained" color="primary" onClick={() => setOpen(true)} disabled={loadingData}>
                      +
                    </Button>
                </ButtonGroup>
              )
            }
          </Grid>
          <Grid item>
            <TextField
              id="filled-select-payment"
              select
              label="Moyen de paiement"
              value={payment}
              onChange={event => setChange('payment', event.target.value)}
              fullWidth
              variant="filled"
              required
            >
              <MenuItem value={"Cash"}>
                <MoneyIcon /> &nbsp;Cash
              </MenuItem>
              <MenuItem value={"Cash Non Payé"}>
                <MoneyIcon /> &nbsp;Cash Non Payé
              </MenuItem>
              <MenuItem value={"Bancontact"}>
                <PaymentIcon /> &nbsp;Bancontact
              </MenuItem>
              <MenuItem value={"Facture"}>
                <DescriptionIcon /> &nbsp;Facture
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <Button className={classes.continueBtn} fullWidth color="primary" variant="contained" type="submit" disabled={loadingData}>
              Continuer
            </Button>
          </Grid>
        </Grid>
      </form>
        
      <Dialog fullScreen maxWidth={'md'} aria-labelledby="customized-dialog-title" open={open} onBackdropClick={closeDialog} onExited={onExited}>
        <DialogTitle id="customized-dialog-title" onClose={closeDialog}>
          Ajouter un client
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Chargement du formulaire...
          </DialogContentText>
          <React.Fragment>
            <CircularProgress />
            <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
            <iframe className="airtable-embed airtable-dynamic-height" src={"https://airtable.com/embed/shrEQAYFCGtVdy6p2?backgroundColor=pink"} frameBorder="0" width="100%" style={{ height: "calc(100% - 80px)", position: "absolute", left: "0", right: "0", bottom: "0", top: "80px", "background": "transparent", "border": "1px solid #ccc" }}></iframe>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FirstForm
;