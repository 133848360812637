import React from 'react';

export const MCNFormContext = React.createContext({});

// Dont forget to add this at the root of your project around your <App />
export default class ProvideMCNFormContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: '',
      payment: '',
      products: [],
      getTotals: () => {
        const computeValues = (element) => Number(element.qt) * Number(element.price);

        let priceTVAC = computeValues(this.state.products[0]);

        if (this.state.products.length > 1) {
          priceTVAC = this.state.products.reduce((prev, cur) => {
            // On the first iteration, prev is the first element of the array
            if (isNaN(prev)) {
              prev = computeValues(prev);
            }
  
            return prev + (computeValues(cur))
          });
        }
        

        return {
          priceHTVA: priceTVAC / 1.06,
          vat: priceTVAC / 106 * 6,
          priceTVAC
        };
      },
      setChange: (target, value) => { this.setState({ [target]: value }) },
      addProduct: (prodCode, productName, lotNumber, qtLeft) => { 
        var newArray = [...this.state.products]
        var element = newArray.filter(prod => prod.code === prodCode)[0];
        
        if (element) {
          element.qt = Number(element.qt) + 10;
        } else {
          newArray.push({ code: prodCode, name: productName, qtLeft: qtLeft, lotNumber, qt: Math.min(qtLeft, 10), price: '1.00' });
        }
        this.setState({ products: newArray });
      },
      removeProduct: (product) => {
        this.setState({ products: this.state.products.filter(item => item !== product) });
      },
      updateProp: (prop, productCode, newValue) => {
        const newArray = [...this.state.products]
          .map(prod =>
            prod.code === productCode
              ? { ...prod, [prop]: newValue }
              : { ...prod }
          );

        this.setState({ products: newArray });
      },
    };
  }

  componentDidUpdate() {
  }

  componentDidMount() {
  }

  render() {

    return (
      <React.Fragment>
        <MCNFormContext.Provider value={this.state}>{this.props.children}</MCNFormContext.Provider>
      </React.Fragment>
    );
  }
}