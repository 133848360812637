import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { AirtableContext } from '../../../contexts/AirtableContext';
import { CustomizedSnackBarContext } from '../../../contexts/CustomizedSnackbarContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: "25px 15px",
    marginBottom: "15px",
  },
  input: {
    marginTop: '16px'
  },
  btn: {
    marginTop: '16px'
  },
  ...theme.common,
}));

const LotVenteInfo = ({ venteId, venteDetail }) => {
  const classes = useStyles();

  const { updateLotVente } = React.useContext(AirtableContext);
  const { errorMessage, successMessage } = React.useContext(CustomizedSnackBarContext);

  const [qt, setQt] = React.useState(venteDetail.qt);
  const [price, setPrice] = React.useState(venteDetail.price);

  const updateValues = async () => {
    const result = await updateLotVente(venteId, venteDetail.id, Number(qt), Number(price));

    if (result) {
      successMessage("Action effectué avec succès");
    } else {
      errorMessage("Une erreur s'est produite. Les données sont-elles correctes ?");
    }
  };
  
  return (
    <Paper className={classes.root}>
      <Typography>Produit: <b>{venteDetail.name}</b></Typography>
      <TextField
        id="filled-qt"
        className={classes.input}
        label="Quantité (Kg)"
        type="number"
        variant="outlined"
        fullWidth
        value={qt}
        InputLabelProps={{ shrink: true }}
        inputProps={{ inputMode: 'numeric' }}
        onKeyDown={(evt) => (["e", "E", "+", "-"].includes(evt.key)) && evt.preventDefault()}
        onChange={event => setQt(event.target.value)}
      />
      <TextField
        id="filled-qt"
        className={classes.input}
        label="Prix (Kg)"
        type="number"
        variant="outlined"
        fullWidth
        value={price}
        InputLabelProps={{ shrink: true }}
        inputProps={{ inputMode: 'numeric' }}
        onKeyDown={(evt) => (["e", "E", "+", "-"].includes(evt.key)) && evt.preventDefault()}
        onChange={event => setPrice(event.target.value)}
      />
      <Button className={classes.btn} color="primary" variant="contained" onClick={updateValues}>Mettre à jour</Button>
    </Paper>
  );
};

export default LotVenteInfo;