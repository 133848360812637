import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { MCNFormContext } from '../../../contexts/MCNFormContext';
import { AirtableContext } from '../../../contexts/AirtableContext';
import { DialogContext } from '../../../contexts/DialogContext';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "35px",
  },
  retryBtn: {
    marginTop: "35px",
  },
  ...theme.common,
}));

const FinalForm = ({closeDialogPlease}) => {
  const classes = useStyles();

  const [status, setStatus] = React.useState('loading');
  const { client, payment, products } = React.useContext(MCNFormContext);
  const { saveSale } = React.useContext(AirtableContext);

  const saveData = async () => {
    setStatus('loading');

    var result = await saveSale(client, payment, products);

    setStatus(result ? "saved" : "error");

    if (result) {
      setTimeout(() => closeDialogPlease(), 1750);
    }
  };

  React.useEffect(() => {
    saveData();
  }, []);

  return (
    <Grid className={classes.root} container justify="center" alignItems="center">
      <Grid item>
        {
          status === 'loading' && (
            <CircularProgress />
          )
        }
        {
          status === 'saved' && (
            <Typography variant="h5"><CheckCircleOutlineIcon color="success" /> Sauvegardé avec succès</Typography>
          )
        }
        {
          status === 'error' && (
            <React.Fragment>
              <Typography variant="h5"><WarningIcon color="error" /> Une erreur s'est produite</Typography>
              <Button className={classes.retryBtn} fullWidth variant="contained" color="primary" onClick={saveData}>
                Re-essayer
              </Button>
            </React.Fragment>
          )
        }
      </Grid>
    </Grid>
  );
};

export default FinalForm;